import { ShipmentStop } from 'api/genTypes/dto';
import { DataTableColumn } from 'components/DataTable/DataTable.types';

export function useNCADetailsShipmentStops(shipmentStops?: ShipmentStop[]) {
  const columns: DataTableColumn<ShipmentStop>[] = [
    {
      columnId: 'shipmentStop',
      columnType: 'string',
    },
    {
      columnId: 'stopType',
      columnType: 'string',
    },
    {
      columnId: 'plannedArrivalDateTime',
      columnType: 'date',
    },
    {
      columnId: 'plannedDepartureDateTime',
      columnType: 'date',
    },
    {
      columnId: 'actualArrivalDateTime',
      columnType: 'date',
    },
    {
      columnId: 'actualDepartureDateTime',
      columnType: 'date',
    },
    {
      columnId: 'performanceReasonCode',
      columnType: 'string',
    },
    {
      columnId: 'remarks',
      columnType: 'string',
    },
    {
      columnId: 'cosigner',
      columnType: 'string',
    },
    {
      columnId: 'cosignee',
      columnType: 'string',
    },
    {
      columnId: 'isPOL',
      columnType: 'boolean',
    },
    {
      columnId: 'isPOD',
      columnType: 'boolean',
    },
  ];

  return {
    columns,
    data: shipmentStops,
  };
}
