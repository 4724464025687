import { appConfig } from 'appConfig';
import { configuredAxios } from './configuredAxios';
import { AxiosResponse } from 'axios';
import { ConfigurationRequestSchema, ConfigurationsSchema } from './genTypes/dto';

export const configurationsAPI = {
  fetchMany: (classification: string) => {
    return configuredAxios
      .get<ConfigurationRequestSchema, AxiosResponse<ConfigurationsSchema>>(
        appConfig.configurationsEndpoint,
        {
          params: new URLSearchParams({ classification: classification }),
        }
      )
      .then((result) => result.data);
  },
};
