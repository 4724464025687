import Button from '@ingka/button';
import { Select, Option } from '@ingka/select';
import { useTranslate } from 'hooks/useTranslate';
import {
  NCAControlsStateForActions,
  NCAControlsStateForReasonCodes,
  NCAControlsStateForSubmitting,
} from '../../NCAControls.types';
import { Confirmation } from 'components/common/Confirmation/Confirmation';
import styled from 'styled-components';
import { StackSpaced } from 'components/layout';

const ButtonFullWidth = styled(Button)`
  width: 100%;
`;

export function NCAControlsActionSelect({
  isAnyClaimActionPossible,
  actionState,
  reasonCodeState,
  submittingState,
}: {
  isAnyClaimActionPossible?: boolean;
  actionState: NCAControlsStateForActions;
  reasonCodeState: NCAControlsStateForReasonCodes;
  submittingState: NCAControlsStateForSubmitting;
}) {
  const t = useTranslate();

  return (
    <StackSpaced>
      {/* Select: Action */}
      <Select
        id="NCAControlsActionSelectId"
        data-testid="NCAControlsActionSelectId"
        label={t('selectAction')}
        multiple={false}
        value={actionState.action}
        onChange={(e) => actionState.setAction(e.target.value)}
        disabled={!isAnyClaimActionPossible}
      >
        {/* <Option id="action-noValue" name="-" value={''} /> */}
        {actionState.actionOptions.map((action) => (
          <Option
            key={'nca-controls-action-' + action.key}
            id={action.key}
            name={t(action.key)}
            value={action.key}
            disabled={action.disabled}
          />
        ))}
      </Select>

      {/* Select: Reason Code */}
      {true && (
        <Select
          id="NCAControlsReasonCodeSelectId"
          data-testid="NCAControlsReasonCodeSelectId"
          label={t('reasonCode')}
          multiple={false}
          value={reasonCodeState.reasonCode}
          onChange={(e) => reasonCodeState.setReasonCode(e.target.value)}
          disabled={reasonCodeState.isReasonCodesDropdownDisabled}
        >
          {/* <Option id="reasonCode-noValue" name="-" value={''} /> */}
          {reasonCodeState.reasonCodeOptions.map((reasonCode) => (
            <Option
              key={'reasonCode-' + reasonCode.code}
              id={reasonCode.code}
              name={reasonCode.message}
              value={reasonCode.code}
            />
          ))}
        </Select>
      )}

      {/* Submit changes */}
      <Confirmation
        handleOnConfirmed={submittingState.handleOnSubmit}
        triggerElement={
          <ButtonFullWidth type="emphasised" disabled={submittingState.isSubmitDisabled}>
            {t('saveAndSubmit')}
          </ButtonFullWidth>
        }
        disabled={submittingState.isSubmitDisabled}
      />
    </StackSpaced>
  );
}
