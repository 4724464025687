import { GetOneNCAResponse } from 'api/genTypes/dto';
import { ncaAPI } from 'api/ncaAPI';
import { queryClient } from 'components/App';
import { useNcaDetails } from 'pages/nca/components/NCADetails/hooks/useNcaDetails';
import { useEffect, useState } from 'react';
import {
  NCAControlsStateForActions,
  NCAControlsStateForReasonCodes,
  NCAControlsStateForResolutionComment,
  NCAControlsStateForSubmitting,
  UseNCAControlsResult,
} from '../NCAControls.types';
import { NcaActions } from 'pages/nca/constants/ncaActions';
import { ConfigurationRejectionReasonCode } from 'hooks/useConfigurations.types';
import { useRejectionReasonCodes } from 'hooks/useConfigurations';

export function useNCAControlsForAction(
  nca: GetOneNCAResponse,
  isAnyClaimActionPossible?: boolean
): NCAControlsStateForActions {
  const [action, setAction] = useState<string | undefined>('');
  const resetAction = () => setAction('');
  const isActionDropdownDisabled: boolean = !isAnyClaimActionPossible;
  const actionOptions: { key: string; disabled?: boolean }[] = [
    {
      key: NcaActions.Approve,
      disabled: !nca?.isAcceptActionAllowed,
    },
    {
      key: NcaActions.Reject,
      disabled: !nca?.isRejectActionAllowed,
    },
    {
      key: NcaActions.SendToManagerApproval,
      disabled: !nca?.isSendToLdmActionAllowed,
    },
  ];

  return {
    action,
    setAction,
    resetAction,
    isActionDropdownDisabled,
    actionOptions,
  };
}

export function useNCAControlsForReasonCodes(
  action?: string,
  isAnyClaimActionPossible?: boolean
): NCAControlsStateForReasonCodes {
  const [reasonCode, setReasonCode] = useState<string | undefined>('');
  const resetReasonCode = () => setReasonCode('');
  const isReasonCodesDropdownDisabled: boolean =
    action !== NcaActions.Reject || !isAnyClaimActionPossible;
  const reasonCodeOptions: ConfigurationRejectionReasonCode[] = useRejectionReasonCodes();

  return {
    reasonCode,
    setReasonCode,
    resetReasonCode,
    isReasonCodesDropdownDisabled,
    reasonCodeOptions,
  };
}

export function useNCAControlsForResolutionComment(
  nca: GetOneNCAResponse,
  isAnyClaimActionPossible?: boolean
): NCAControlsStateForResolutionComment {
  const resolutionCommentDefaultValue: string = nca?.resolutionNotes || '';
  const [resolutionComment, setResolutionComment] = useState<string | undefined>(
    resolutionCommentDefaultValue
  );
  const resetResolutionComment = () => setResolutionComment(resolutionCommentDefaultValue);
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    setResolutionComment(nca?.resolutionNotes || '');
  }, [nca]);

  // Don't allow resolution comment to be edited if the claim cannot be submitted.
  // Otherwise, allow it.
  const isResolutionCommentDisabled: boolean = !isAnyClaimActionPossible;
  return {
    resolutionComment,
    setResolutionComment,
    resetResolutionComment,
    isResolutionCommentDisabled,
    showError,
    setShowError,
  };
}

export function useNCAControlsForSubmitting(
  nca: GetOneNCAResponse,
  actionState: NCAControlsStateForActions,
  reasonCodeState: NCAControlsStateForReasonCodes,
  resolutionCommentState: NCAControlsStateForResolutionComment
): NCAControlsStateForSubmitting {
  // Used to reload the data when we update
  const { refetch } = useNcaDetails();

  // Handle submitting data and the side-effects thereof
  const handleOnSubmit = () => {
    if (isSubmitDisabled) {
      // If for some reason we manage to submit without the submit action being allowed,
      // prevent it and show an error message.
      setIsSuccessNotificationVisible(false);
      setIsFailNotificationVisible(true);

      return;
    }

    ncaAPI
      .post({
        nonConformityAdviceId: nca?.nonConformityAdviceId,
        requested_action: actionState.action,
        reasonCode: reasonCodeState.reasonCode,
        resolutionComment: resolutionCommentState.resolutionComment,
      })
      .then(() => {
        // Show success notification.
        setIsSuccessNotificationVisible(true);
        setIsFailNotificationVisible(false);
        resolutionCommentState.setShowError(false);

        // Reset action, reasonCode and resolution comment if we successfully update.
        actionState.resetAction();
        reasonCodeState.resetReasonCode();

        // Resolution comment should get its default value from the updated server state.
        resolutionCommentState.resetResolutionComment();
      })
      .catch((e) => {
        // If we fail to update, we show a failure notification,
        // but we do not reset any controls since we expect the user
        // may want to try submitting again without losing their input.
        setIsSuccessNotificationVisible(false);
        setIsFailNotificationVisible(true);
        resolutionCommentState.setShowError(true);
      })
      .finally(() => {
        // Reload the current NCADetails page
        refetch();

        // Invalidate the NCAList query, so that the next time we open NCAList,
        // we re-load the list with the latest info.
        queryClient.invalidateQueries({ queryKey: 'NCA_OVERVIEW_QUERY' });
      });
  };

  // Action statuses
  const isNoActionSelected: boolean = !actionState.action;
  const isRejectActionSelected: boolean = actionState.action === NcaActions.Reject;

  // Reason code statuses
  const isNoReasonCodeSelected: boolean = !reasonCodeState.reasonCode;
  const isNoReasonCodeSelectedButRequired: boolean =
    isNoReasonCodeSelected && isRejectActionSelected;

  // Resolution comment statuses
  const isNoResolutionCommentProvided: boolean = !resolutionCommentState.resetResolutionComment;

  // Submit statuses
  const isSubmitDisabled: boolean =
    isNoActionSelected || isNoReasonCodeSelectedButRequired || isNoResolutionCommentProvided;

  // Notifcation state management
  const [isSuccessNotificationVisible, setIsSuccessNotificationVisible] = useState<boolean>(false);
  const [isFailNotificationVisible, setIsFailNotificationVisible] = useState<boolean>(false);

  return {
    handleOnSubmit,
    isSubmitDisabled,
    isSuccessNotificationVisible,
    setIsSuccessNotificationVisible,
    isFailNotificationVisible,
    setIsFailNotificationVisible,
  };
}

export function useNCAControls(nca: GetOneNCAResponse): UseNCAControlsResult {
  const isAnyClaimActionPossible: boolean =
    nca?.isAcceptActionAllowed || nca?.isRejectActionAllowed || nca?.isSendToLdmActionAllowed;

  // Action
  const actionState = useNCAControlsForAction(nca, isAnyClaimActionPossible);
  // Reason code
  const reasonCodeState = useNCAControlsForReasonCodes(
    actionState.action,
    isAnyClaimActionPossible
  );
  // Resolution comment
  const resolutionCommentState = useNCAControlsForResolutionComment(nca, isAnyClaimActionPossible);
  // Submitting
  const submittingState = useNCAControlsForSubmitting(
    nca,
    actionState,
    reasonCodeState,
    resolutionCommentState
  );

  // Side-effects
  useEffect(() => {
    if (actionState.action !== NcaActions.Reject) {
      reasonCodeState.resetReasonCode();
    }
  }, [actionState.action, reasonCodeState.resetReasonCode, reasonCodeState]);

  return {
    isAnyClaimActionPossible,
    actionState,
    reasonCodeState,
    resolutionCommentState,
    submittingState,
  };
}
