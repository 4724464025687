import { Subsection } from 'components/common/Subsection';
import { NCAListTable } from './components/NCAListTable/NCAListTable';
import { useNCAList } from './hooks/useNCAList';
import { NCAListFilter } from './components/NCAListFilter/NCAListFilter';

/** NCAList is a page that shows a filterable list of all NCAs. */
export function NCAList() {
  const { columns, data, isLoading, error } = useNCAList();
  return (
    <>
      <Subsection>
        <NCAListFilter />
      </Subsection>
      <Subsection>
        <NCAListTable columns={columns} data={data} isLoading={isLoading} error={error} />
      </Subsection>
    </>
  );
}
