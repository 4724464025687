import { translations } from '../constants/translations';

/** Provides a function `translate` to translate keys into human-readable text strings */
export function useTranslate(): (key: keyof typeof translations) => string {
  const translate = (key: keyof typeof translations): string => {
    const value: string | undefined = translations[key];

    if (!value) {
      return key.toString();
    }

    return value;
  };

  return translate;
}
