import Toast from '@ingka/toast';
import { UnexpectedError } from 'components/alerts';
import styled from 'styled-components';
import { useNCAControls } from './hooks/useNCAControls';
import { NCAControlsProps } from './NCAControls.types';
import { useTranslate } from 'hooks/useTranslate';
import { Row } from 'components/layout';
import { NCAControlsActionSelect } from './components/NCAControlsActionSelect/NCAControlsActionSelect';
import { NCAControlsResolutionComment } from './components/NCAControlsResolutionComment/NCAControlsResolutionComment';

export const NCAControlsLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  flex-grow: 1;
  align-items: stretch;
  gap: 1rem;
`;

/** Provides the controls needed to handle a single claim (i.e.: accept, reject, or send to LDM, etc.). */
export function NCAControls({ nca }: NCAControlsProps) {
  const {
    isAnyClaimActionPossible,
    actionState,
    reasonCodeState,
    resolutionCommentState,
    submittingState,
  } = useNCAControls(nca);
  const t = useTranslate();

  if (!nca) {
    return <UnexpectedError />;
  }

  return (
    <>
      {/* NCA Claim Controls */}
      <Row>
        {/* Resolution Comment */}
        <NCAControlsResolutionComment resolutionCommentState={resolutionCommentState} />

        {/* Claim Action */}
        <NCAControlsActionSelect
          isAnyClaimActionPossible={isAnyClaimActionPossible}
          actionState={actionState}
          reasonCodeState={reasonCodeState}
          submittingState={submittingState}
        />
      </Row>

      {/* Notifications */}

      {/* Notifications - Success */}
      <Toast
        text={t('claimUpdated')}
        isOpen={submittingState.isSuccessNotificationVisible}
        onCloseRequest={() => {
          submittingState.setIsSuccessNotificationVisible(false);
        }}
        ariaLabelCloseBtn={t('dismissNotification')}
      />

      {/* Notifications - Failure */}
      <Toast
        text={t('claimUpdateFailed')}
        isOpen={submittingState.isFailNotificationVisible}
        onCloseRequest={() => {
          submittingState.setIsFailNotificationVisible(false);
        }}
        ariaLabelCloseBtn={t('dismissNotification')}
      />
    </>
  );
}
