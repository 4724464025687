import { GetOneNCAResponse } from 'api/genTypes/dto';
import { useNCADetailsBasicData } from './hooks/useNCADetailsBasicData';
import { DataDetails } from 'components/DataDetails/DataDetails';

export interface NCADetailsBasicDataProps {
  nca?: GetOneNCAResponse;
}

export function NCADetailsBasicData({ nca }: NCADetailsBasicDataProps) {
  const { columns } = useNCADetailsBasicData();

  return <DataDetails<GetOneNCAResponse> columns={columns} data={nca} />;
}
