import { useSearchParams } from 'react-router-dom';
import { NCAListFilterBadge } from '../NCAListFilter.types';

export function useNCAListFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleFilterCleared = (key: string): void => {
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.delete(key);

    setSearchParams(newSearchParams);
  };

  const handleClearAllFilters = (): void => {
    setSearchParams(new URLSearchParams());
  };

  const searchParamBadgeData: NCAListFilterBadge[] = [];

  searchParams.forEach((value: string, key: string) => {
    searchParamBadgeData.push({
      key: key,
      value: value,
    });
  });

  return {
    searchParams: searchParamBadgeData,
    handleFilterCleared,
    handleClearAllFilters,
  };
}
