//Deploy to feature #1
import { getTemplatedAppNameOrDefault } from 'utils/getTemplatedAppNameOrDefault';

declare var window: any;

export const appConfig = {
  appName: getTemplatedAppNameOrDefault('Extra Cost Handling'),
  customerEndpoint:
    window?.env?.CUSTOMER_BACKEND_ENDPOINT || process.env.REACT_APP_CUSTOMER_BACKEND_ENDPOINT,
  ncaEndpoint: window?.env?.API_ENDPOINT_NCA || process.env.REACT_APP_API_ENDPOINT_NCA,
  configurationsEndpoint:
    window?.env?.API_ENDPOINT_CONFIGURATIONS || process.env.REACT_APP_API_ENDPOINT_CONFIGURATIONS,
  appClientId: window?.env?.CLIENT_ID || process.env.REACT_APP_CLIENT_ID,
  appTenantId: window?.env?.TENANT_ID || process.env.REACT_APP_TENANT_ID,
  appScope: window?.env?.SCOPE || process.env.REACT_APP_SCOPE,
  appInsightKey: window?.env?.INSIGHTS_KEY || process.env.REACT_APP_INSIGHTS_KEY,
  version: 'v0.22',
};

if (!Object.values(appConfig).every((x) => x !== null && x !== '' && x !== undefined)) {
  throw Error(`Missing value for env variables in env-config`);
}
