import Badge from '@ingka/badge';
import { Link } from 'react-router-dom';
import { DataTableEntity } from '../../DataTable.types';
import { DataTableCellProps } from './DataTableCell.types';

export function DataTableCell<T extends DataTableEntity>({
  column,
  dataItem: entity,
  isTable,
}: DataTableCellProps<T>) {
  const renderValue = (value: any): string => {
    if (!value) {
      return undefined;
    }

    const text: string = String(value);

    // If isTable is true, we cut off the text to keep the table cell content a reasonable size.
    if (isTable) {
      return text.slice(0, 50) + (text.length > 50 ? '...' : '');
    }

    return text;
  };

  // Render nothing if the dataItem is not defined.
  if (!entity) {
    return null;
  }

  const value = entity[column.columnId];

  if (column.columnType === 'boolean') {
    return <input type="checkbox" checked={Boolean(value)} disabled />;
  }

  if (column.columnType === 'date' && value) {
    const dateFormatRegex: RegExp =
      /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}):\d{2}\.\d{3}([+-]\d{2}:\d{2})$/;
    const dateFormatMatch = renderValue(value).match(dateFormatRegex);

    if (dateFormatMatch) {
      const [, datePart, timePart, timezonePart] = dateFormatMatch;

      return <>{`${datePart} ${timePart} ${timezonePart}`}</>;
    }
  }

  if (column.columnType === 'link') {
    return <Link to={entity[column.columnId]}>{renderValue(entity[column.columnId])}</Link>;
  }

  if (column.columnType === 'select') {
    const option = column.options.find((o) => o.value === value);

    if (option) {
      return (
        <Badge label={option.label || option.value} colour={option.color || 'blue'} size="small" />
      );
    }
  }

  return <>{renderValue(entity[column.columnId])}</>;
}
