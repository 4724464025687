import { NCADto } from 'api/genTypes/dto';
import { DataTable } from 'components/DataTable/DataTable';
import { DataTableColumn } from 'components/DataTable/DataTable.types';

/** NCAListTable is responsible for the display-only of the NCA List data.
 * Querying and filtering logic is delegated to other components.
 */
export function NCAListTable({
  data,
  isLoading,
  error,
  columns,
}: {
  data: NCADto[] | undefined;
  isLoading: boolean;
  error: unknown | undefined;
  columns: DataTableColumn<NCADto>[];
}) {
  return (
    <DataTable<NCADto>
      columns={columns}
      data={data}
      isLoading={isLoading}
      error={error}
      idField="nonConformityAdviceId"
      withFiltering
      withServerData
    />
  );
}
