import { useNCAListFilter } from './hooks/useNCAListFilter';
import Button from '@ingka/button';
import { useTranslate } from 'hooks/useTranslate';
import { WrappingRow } from 'components/common/WrappingRow/WrappingRow';
import { styled } from 'styled-components';

export const BadgeContent = styled.span`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
`;

export const BadgeValue = styled.span`
  font-style: italic;
`;

export const BadgeIcon = styled.span`
  border: 1px solid grey;
  padding: 0 0.3rem;
  border-radius: 1rem;
`;

export function NCAListFilter() {
  const t = useTranslate();
  const { searchParams, handleFilterCleared, handleClearAllFilters } = useNCAListFilter();

  return (
    <WrappingRow>
      <Button
        onClick={handleClearAllFilters}
        text={t('clearAllFilters')}
        size="small"
        type="primary"
        disabled={searchParams.length === 0}
      />
      {searchParams.map((param) => (
        <span key={'nca-filter-badge-' + param.key} onClick={() => handleFilterCleared(param.key)}>
          <Button
            text={
              <BadgeContent>
                <span>
                  {t(param.key)}: <BadgeValue>{param.value}</BadgeValue>
                </span>
                <BadgeIcon>X</BadgeIcon>
              </BadgeContent>
            }
            size="xsmall"
            type="secondary"
          />
        </span>
      ))}
    </WrappingRow>
  );
}
