import { NCADto } from 'api/genTypes/dto';
import { DataTableColumn } from 'components/DataTable/DataTable.types';
import { ncaListColumns } from 'pages/nca/constants/ncaColumns';

/** useNCAList manages the logic of the NCA List page. */
export function useNCAListColumns(): {
  columns: DataTableColumn<NCADto>[];
} {
  return {
    columns: ncaListColumns,
  };
}
