import { DataTableEntity } from 'components/DataTable/DataTable.types';
import { DataTableColumnHeaderProps } from './DataTableColumnHeader.types';
import { useTranslate } from 'hooks/useTranslate';
import { DataTableColumnHeaderFilter } from './components/DataTableColumnHeaderFilter/DataTableColumnHeaderFilter';

export function DataTableColumnHeader<T extends DataTableEntity>({
  column,
  withFiltering,
}: DataTableColumnHeaderProps<T>) {
  const t = useTranslate();

  if (withFiltering) {
    return <DataTableColumnHeaderFilter column={column} />;
  }

  return <>{t(column.columnId)}</>;
}
