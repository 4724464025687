import './NCADetails.css';
import { Subsection } from 'components/common/Subsection';
import { DataContentWrapper } from 'components/common/DataWrapper';
import { useNcaDetails } from './hooks/useNcaDetails';
import { useTranslate } from 'hooks/useTranslate';
import { NCADetailsBasicData } from './components/NCADetailsBasicData/NCADetailsBasicData';
import { NCAControls } from '../NCAControls/NCAControls';
import { NCADetailsShipmentStops } from './components/NCADetailsShipmentStops/NCADetailsShipmentStops';
import { NCADetailsEventHistory } from './components/NCADetailsEventHistory/NCADetailsEventHistory';
import Text from '@ingka/text';
import { NCAControlsLog } from '../NCAControls/components/NCAControlsLog/NCAControlsLog';

export function NCADetails() {
  const translate = useTranslate();
  const { data, isLoading, error } = useNcaDetails();

  return (
    <DataContentWrapper
      isLoading={isLoading}
      error={error}
      data={data}
      noDataMessage={translate('ncaNotFound')}
    >
      <>
        <Subsection header={<Text headingSize="s">{translate('claimDetails')}</Text>}>
          <NCADetailsBasicData nca={data} />
        </Subsection>

        <Subsection header={<Text headingSize="s">{translate('shipmentStops')}</Text>}>
          <NCADetailsShipmentStops shipmentStops={data?.shipmentStops} />
        </Subsection>

        <Subsection header={<Text headingSize="s">{translate('eventHistory')}</Text>}>
          <NCADetailsEventHistory eventHistory={data?.eventHistory} />
        </Subsection>

        <Subsection header={<Text headingSize="s">{translate('attachments')}</Text>}>
          Todo: Attachments
        </Subsection>

        <Subsection header={<Text headingSize="s">{translate('claimHistory')}</Text>}>
          <NCAControlsLog />
        </Subsection>

        <Subsection header={<Text headingSize="s">{translate('handleNca')}</Text>}>
          <NCAControls nca={data} />
        </Subsection>
      </>
    </DataContentWrapper>
  );
}
