import { GetManyNCAsResponse } from 'api/genTypes/dto';
import { ncaAPI } from 'api/ncaAPI';
import { useQuery } from 'react-query';

export function useNCAListQuery(params: URLSearchParams) {
  return useQuery<GetManyNCAsResponse>({
    // The params here mean that every time we change our search params,
    // the query is invalidated and must be refetched.
    queryKey: ['NCA_OVERVIEW_QUERY', params.toString()],
    queryFn: () => ncaAPI.fetchMany(params),
  });
}
