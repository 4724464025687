import { appConfig } from 'appConfig';
import { configuredAxios } from './configuredAxios';
import {
  GetManyNCAsRequest,
  GetManyNCAsResponse,
  GetOneNCARequest,
  GetOneNCAResponse,
  NCAProcessingRequestSchema,
} from './genTypes/dto';
import { AxiosResponse } from 'axios';

export const ncaAPI = {
  fetchMany: (params: URLSearchParams) => {
    return configuredAxios
      .get<GetManyNCAsRequest, AxiosResponse<GetManyNCAsResponse>>(appConfig.ncaEndpoint, {
        params: params,
      })
      .then((result) => result.data);
  },
  fetchOne: (r: GetOneNCARequest) => {
    return configuredAxios
      .get<GetOneNCARequest, AxiosResponse<GetOneNCAResponse>>(
        `${appConfig.ncaEndpoint}/${r.nonConformityAdviceId}`
      )
      .then((result) => result.data);
  },
  post: (request: NCAProcessingRequestSchema) => {
    return configuredAxios
      .post<NCAProcessingRequestSchema, AxiosResponse<void>>(appConfig.ncaEndpoint, request)
      .then((result) => result.data);
  },
};
