import { Routes, Route } from 'react-router-dom';
import { NCADetails } from './components/NCADetails/NCADetails';
import { NCAList } from './components/NCAList/NCAList';

export function NCAPage() {
  return (
    <Routes>
      <Route path=":nonConformityAdviceId" element={<NCADetails />} />
      <Route
        path=""
        element={
          <>
            <NCAList />
          </>
        }
      />
    </Routes>
  );
}
