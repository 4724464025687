import { DataContentWrapper } from 'components/common/DataWrapper';
import { DataTableCell } from 'components/DataTable/components/DataTableCell/DataTableCell';
import { DataTableColumn, DataTableEntity } from 'components/DataTable/DataTable.types';
import { useTranslate } from 'hooks/useTranslate';
import { styled } from 'styled-components';

export interface DataDetailsProps<T extends DataTableEntity> {
  /** The columns displayed in the Data Table. */
  columns: DataTableColumn<T>[];
  /** The data to be shown. */
  data: T | undefined;
  /** Indicates that the table data is still loading. */
  isLoading?: boolean;
  /** Any errors that should prevent the table from loading. */
  error?: unknown;

  // TODO: Consider extending the props with an onChange property so we can handle someone editing data.
}

const DataDetailsList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.25rem;
  margin-top: 1rem;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const DataDetailsItem = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dfdfdf;
  padding: 0.25rem 0;
`;

const DataDetailsItemLabel = styled.div`
  font-weight: bold;
  &:after {
    content: '';
  }
`;

const DataDetailsItemValue = styled.div`
  font-weight: 300;
  line-break: anywhere;
`;

/** DataTable represents a generic data table which renders
 * the provided data in the browser according to the columns provided. */
export function DataDetails<T extends DataTableEntity>({
  columns,
  data,
  isLoading,
  error,
}: DataDetailsProps<T>) {
  const t = useTranslate();

  return (
    <>
      <DataDetailsList>
        {columns.map((c) => (
          <DataDetailsItem key={`data-details-item-${c.columnId}`} data-testid={c.columnId}>
            <DataDetailsItemLabel>{t(c.columnId)}</DataDetailsItemLabel>
            <DataDetailsItemValue>
              <DataTableCell<T> column={c} dataItem={data} />
            </DataDetailsItemValue>
          </DataDetailsItem>
        ))}
      </DataDetailsList>

      {/* Render loading, data, and error alerts outside the table body to ensure better formatting */}
      <DataContentWrapper data={data} isLoading={isLoading} error={error} />
    </>
  );
}
