import Toast, { ToastText } from '@ingka/toast';
import createZState from 'zustand';
import skapaToken from '@ingka/variables';

type ToastState = {
  isVisible: boolean;
  close: () => void;
  open: (message: ToastText, type: ToastState['messageType']) => void;
  message: ToastText;
  messageType: 'INFO' | 'ERROR';
};

export const useToast = createZState<ToastState>((set) => ({
  isVisible: false,
  message: '',
  messageType: 'INFO',
  close: () => set((s) => ({ ...s, isVisible: false })),
  open: (message, type) => set((s) => ({ message, isVisible: true, messageType: type })),
}));

export const ToastAnchor = () => {
  const toast = useToast();
  /* eslint-disable no-unused-vars */
  const bgColor: { [_ in ToastState['messageType']]: string } = {
    INFO: skapaToken.colourSemanticPositive,
    ERROR: skapaToken.colourSemanticNegative,
  };

  return (
    <Toast
      style={{
        backgroundColor: bgColor[toast.messageType],
        borderColor: bgColor[toast.messageType],
      }}
      text={toast.message}
      isOpen={toast.isVisible}
      onCloseRequest={() => toast.close()}
      ariaLabelCloseBtn="Dismiss notification"
    />
  );
};
