import { ShipmentStop } from 'api/genTypes/dto';
import { useNCADetailsShipmentStops } from './hooks/useNCADetailsShipmentStops';
import { DataTable } from 'components/DataTable/DataTable';

export interface NCADetailsShipmentStopsProps {
  shipmentStops?: ShipmentStop[];
}

export function NCADetailsShipmentStops({ shipmentStops }: NCADetailsShipmentStopsProps) {
  const { columns, data } = useNCADetailsShipmentStops(shipmentStops);

  return <DataTable<ShipmentStop> columns={columns} data={data} />;
}
